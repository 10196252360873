import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Constant } from 'src/app/commons/Constants';
import { MessageEn } from 'src/app/commons/MessageEn';
import { MessageJp } from 'src/app/commons/MessageJp';
import { Utils } from 'src/app/commons/Utils';
import { halfwidth, selected } from 'src/app/custom-validators/halfwidth';
import { AppService } from 'src/app/service/app.service';
import { PulldownService } from 'src/app/service/pulldown.service';
import { ShippingFeeService } from 'src/app/service/shipping-fee.service';
import { ShippingService } from 'src/app/service/shipping.service';
import { WarningPopupComponent } from '../warning-popup/warning-popup.component';

@Component({
  selector: 'app-regist',
  templateUrl: './regist.component.html',
  styleUrls: ['./regist.component.scss'],
})
export class RegistComponent implements OnInit {
  regex = /[（(]/

  pdFacility: Array<any> = [];

  hotelPlaceHolder: string = '';
  customerPlaceHolder: string = '';
  telPlaceHolder: string = '';
  emailPlaceHolder: string = '';

  responseLang: any[] = [];

  listBaggageWithLang: any[] = [];

  listBaggageResponse: any[] = [];

  baggageTotalPrice: number = 0;

  mes = MessageJp;

  currrentLang: number = 0;

  submitFlag: boolean = false;

  discountAvail: boolean = false;

  data: any = {};

  defaultUsageDate: Date;

  minSelectableDate: Date;
  maxSelectableDate: Date;
  disabledDates: Date[] = [
    new Date('2024-02-01'), new Date('2024-02-02'),
    new Date('2024-02-03'), new Date('2024-02-04'),
    new Date('2024-02-05'), new Date('2024-02-06')
  ];

  ref: DynamicDialogRef | undefined;

  constructor(
    private shippingFeeService: ShippingFeeService,
    private shippingService: ShippingService,
    private pdService: PulldownService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService
  ) {
    this.defaultUsageDate = new Date();
    this.minSelectableDate = new Date();
    this.maxSelectableDate = new Date();
    this.maxSelectableDate.setDate(this.minSelectableDate.getDate() + 28);
  }

  formData: FormGroup = new FormGroup({});
  dialogFlag: boolean = false;

  ngOnInit(): void {
    this.appService.selectedLang == 0
    ? (this.mes = MessageJp)
    : (this.mes = MessageEn);

    this.handleLangChange();
    
    this.initFormData();
    this.initPulldown();
    this.calculateTotalPrice();

    const paramURL: any = this.route.snapshot.queryParams;
    if(paramURL.param == Constant.BACK_FROM_CONFIRM || paramURL.param == Constant.UPDATE) {
      this.data = this.appService.getRequest();
      
      this.setFormInfo();
    }

    this.getListBaggage();
    this.getDiscountAvail();
  }

  async getPlaceHolderText() {
    const obj = await this.translate.get('regist').toPromise();
    this.hotelPlaceHolder = obj.hotelPlaceHolder;
    this.customerPlaceHolder = obj.customerPlaceHolder;
    this.telPlaceHolder = obj.telPlaceHolder;
    this.emailPlaceHolder = obj.emailPlaceHolder;
  }

  getDiscountAvail(): any {
    this.shippingService.getDiscountAvail().subscribe({
      next: (res: any) => {
        if (res.status === Constant.HTTP_200) {
          this.discountAvail = res.data;
        }
      },
      error: (err) => console.error(err),
    });
  }

  calculateTotalPrice() {
    this.formData.valueChanges.subscribe({
      next: (value) => {
        this.baggageTotalPrice = 0;
        this.listBaggageWithLang.forEach((item) => {
          this.baggageTotalPrice +=
            item.shippingFeeNormal *
            this.formData.controls['baggage' + item.id]?.value;
        });
      },
    });
  }

  initFormData() {
    if(this.defaultUsageDate <= new Date('2024-02-07 23:59:59')){
      this.defaultUsageDate = new Date('2024-02-07');
    }
    this.formData = new FormGroup({
      stayFacilityId: new FormControl(null, [Validators.required, selected()]),
      reserverName: new FormControl(null, [
        Validators.required,
        halfwidth(this.appService),
      ]),
      usageDate: new FormControl(this.defaultUsageDate, [Validators.required]),
      tel: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  setFormInfo() {
    this.formData.controls['stayFacilityId'].setValue({value: this.data?.stayFacilityId,  name: this.data?.stayFacilityName});
    this.formData.controls['reserverName'].setValue(this.data?.reserverName);
    this.formData.controls['usageDate'].setValue(new Date(this.data?.usageDate));
    this.formData.controls['tel'].setValue(this.data?.tel);
    this.formData.controls['email'].setValue(this.data?.email);
    this.baggageTotalPrice = this.data.shippingFeeTotal;
  }

  initPulldown() {
    let usageDate = Utils.convertDateToSlash(this.formData.value?.usageDate);
    this.pdService.getPdFacility('', usageDate).subscribe({
      next: (res) => {
        if (res.status === Constant.HTTP_200) {
          this.responseLang = res.data;
          this.mapPdToLang(res.data);
        }
      },
      error: (err) => console.error(err),
      complete: () => {
        const selectedFacility = this.formData.value?.stayFacilityId.value;
        const res = this.responseLang.find(x => x.id == selectedFacility);
        if(!res) {
          this.formData.controls['stayFacilityId'].reset();
        }
      }
    });
  }

  filterStayFacility(event: any) {
    let usageDate = Utils.convertDateToSlash(this.formData.value?.usageDate);
    let key = event.query ? event.query : '';

    this.pdService.getPdFacility(key, usageDate).subscribe({
      next: (res) => {
        if (res.status === Constant.HTTP_200) {
          this.responseLang = res.data;
          this.mapPdToLang(res.data);
        }
      },
      error: (err) => console.error(err),
    });
  }

  mapPdToLang(lang: any[]) {
    if (this.appService.selectedLang == 0) {
      this.pdFacility = lang.map((item) => {
        return { value: item.id, name: item.stayFacilityName};
      });
    } else {
      this.pdFacility = lang.map((item) => {
        return { value: item.id, name: item.stayFacilityNameEnglish};
      });
    }
  }

  handleLangChange() {
    this.appService.selectedLangListener.subscribe({
      next: (res) => {
        this.mapPdToLang(this.responseLang);
        this.setListBaggage();
        this.appService.selectedLang == 0
          ? (this.mes = MessageJp)
          : (this.mes = MessageEn);

        this.formData.controls['reserverName'].updateValueAndValidity();
        this.getPlaceHolderText();
      }
    });

    this.getPlaceHolderText();
  }

  getListBaggage() {
    let usageDate = Utils.toStringDateHyphen(this.formData.value?.usageDate);
    this.shippingFeeService.getMasterShippingFee(usageDate).subscribe({
      next: (res) => {
        if (res.status === Constant.HTTP_200) {
          const oldId: number[] = this.listBaggageResponse.map((x) => x.id);
          const newId: number[] = (res.data as Array<any>).map((x) => x.id);
          if (!oldId.equals(newId)) {
            this.listBaggageResponse = res.data;
            this.setListBaggage();
            this.bindingBaggageForm();
            this.formData.updateValueAndValidity();
          }
        }
      },
    });


  }

  setListBaggage() {
    switch (this.appService.selectedLang) {
      case 0:
        this.mapBaggageToLang();
        break;
      case 1:
        this.mapBaggageToLang('En');
        break;
      case 2:
        this.mapBaggageToLang('Ko');
        break;
      case 3:
        this.mapBaggageToLang('Zhch');
        break;
      case 4:
        this.mapBaggageToLang('Zhtw');
        break;
    }
  }

  mapBaggageToLang(lang: string = '') {
    this.listBaggageWithLang = this.listBaggageResponse.map((item) => {
      let oldDetail = null;
      if(this.data?.shipDetail?.length > 0) {
        oldDetail = (this.data?.shipDetail as Array<any>).find(x => x.shippingFeeId == item.id);
      }
      let fee = oldDetail?.shippingUnitFee ? oldDetail?.shippingUnitFee : item.shippingFeeNormal;
      return {
        id: item.id,
        name: item['shippingFeeName' + lang],
        explain: item['explanation' + lang],
        shippingFeeNormal: fee,
        qty: item?.qty,
      };
    });
  }

  bindingBaggageForm() {
    if (this.listBaggageResponse.length > 0) {
      this.listBaggageWithLang.forEach((item) => {
        this.formData.addControl(
          'baggage' + item.id,
          new FormControl(this.getQuantityBackFromConfirm(item.id))
        );
      });
    }
  }

  getQuantityBackFromConfirm(id: number): number {
    try {
      const detail = (this.data?.shipDetail as Array<any>).find(x => x.shippingFeeId == id);
      return detail.quantity;
    } catch (error) {
      return 0;
    }
  }

  markALlDirty() {
    Object.keys(this.formData.controls).forEach((key) => {
      this.formData.get(key)?.markAsDirty();
    });
  }

  moveToConfirm() {
    this.submitFlag = true;
    if (this.formData.invalid || this.baggageTotalPrice <= 0) {
      this.markALlDirty();
      return;
    }

    const shipRequest = this.getShipRequest();
    let selectedFacility = this.responseLang.find(x => x.id == shipRequest.stayFacilityId);
    if(selectedFacility.carefulFlg == 1) {
      this.ref = this.dialogService.open(WarningPopupComponent, { 
        width: screen.width > 450 ? '65%' : '95%',
        showHeader: false,
        position: "top",
        contentStyle: {
          padding: '0px',
        },
        data: {
          stayFacilityName: shipRequest?.stayFacilityName,
          tel: selectedFacility?.tel
        },
      });

      this.ref.onClose.subscribe((flag: boolean) => {
        if(flag) {
          this.next(shipRequest);
        }
      });
    }
    else {
      this.next(shipRequest);
    }
  }

  next(shipRequest: any) {
    this.appService.saveRequest(shipRequest);

    this.router.navigate(['carry-reserve/confirm']).then(() => {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  checkTime1130(): any {
    let usageDate = Utils.convertDateToSlash(this.formData.value?.usageDate);
    this.shippingService.getTimeOver1130(usageDate).subscribe({
      next: (res: any) => {
        if (res.status === Constant.HTTP_200) {
          if(res.data){
            this.openDialog();
          } else {
            this.moveToConfirm();
          }
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.SERVER_ERRR});
      },
    });
  }

  openDialog() {
    this.dialogFlag = true;
  }

  closeDialog() {
    this.dialogFlag = false;
  }

  getShipRequest(): any {
    let qtyTotal = 0
    let shipDetail = this.listBaggageWithLang.map((item) => {
        let qty = this.formData.controls['baggage' + item.id]?.value || 0;
        qtyTotal += qty;
        let oldDetail = null;
        if(this.data?.shipDetail?.length > 0) {
          oldDetail = (this.data?.shipDetail as Array<any>).find(x => x.shippingFeeId == item.id);
        }
        return {
          id: oldDetail?.id || null,
          shippingFeeId: item.id,
          shippingUnitFee: item.shippingFeeNormal,
          quantity: qty,
          shippingFee: item.shippingFeeNormal * qty,
          name: item.name,
          explain: item.explain
        }
    });
    const stayFacilityId = this.formData.value?.stayFacilityId.value;
    const ship = {
      id: this.data?.id,
      shippingAcceptedStatus: this.data?.shippingAcceptedStatus,
      cancelFlag: this.data?.cancelFlag,
      payStatus: this.data?.payStatus,
      ...this.formData.value,
      usageDate: Utils.convertDateToSlash(this.formData.value?.usageDate),
      stayFacilityId: stayFacilityId,
      stayFacilityName: this.pdFacility.find(x => x.value == stayFacilityId)?.name,
      quantityTotal: qtyTotal,
      shippingFeeTotal: this.baggageTotalPrice,
      shipDetail: shipDetail,
      languageSelect: this.appService.selectedLang
    }

    return ship;
  }
}
