// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { HttpHeaders } from "@angular/common/http";

export const environment = {
    production: false,
    // apiUrl: 'http://52.68.71.217:8080',
    apiUrl: 'https://hakonetozanbus.co.jp',
    httpOptions: {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, X-Custom-Header, Upgrade-Insecure-Requests'
        })
    },
    CONFIG: {
        HOTEL_LIST_URL: "https://www.hakonenavi.jp/hakone-luggage-transport-service/jp/doc/carry_hotel_2504.pdf",
        HOTEL_LIST_URL_EN: "https://www.hakonenavi.jp/hakone-luggage-transport-service/en/doc/carry_hotel_en_2504.pdf"
    }
};
