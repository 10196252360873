<div class="container">
    <div class="center-image">
        <img src="/assets/img/carryservice_banner.png" alt="Image" />
        <p class="top-text">{{'top.lblUnderLogo' | translate}}</p>
        <p class="top-text2">{{'top.lblUnderLogo2' | translate}}</p>
        <a class="list-hotel" [href]="translateService.currentLang === 'ja' ? config.HOTEL_LIST_URL : config.HOTEL_LIST_URL_EN " target="_blank" rel="HOTEL_LIST_URL">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.125 2.375H2.375C1.61561 2.375 1 2.99061 1 3.75V10.625C1 11.3844 1.61561 12 2.375 12H9.25C10.0094 12 10.625 11.3844 10.625 10.625V7.875M7.875 1H12M12 1V5.125M12 1L5.125 7.875" stroke="blue" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>{{'top.hotelUrls' | translate}}</span>
        </a>

        <div class="action-content">
            <div class="area">
                <p class="bottom-text">{{'top.lblRequest' | translate}}</p>
                <button pButton (click)="moveToRegist()" class="custom-button p-button-warning">{{'top.btnRequest' | translate}}</button>
            </div>
            <div class="area-mobile">
                <p class="bottom-text">{{'top.lblCheck' | translate}}</p>
                <button (click)="openDialog()" class="custom-button">{{'top.btnCheck' | translate}}</button>
            </div>
            <form class="area-pc" [formGroup]="formUser">
                <p class="">{{'top.lblCheck' | translate}}</p>
                <div class="dialog-content">
                    <div class="form-item">
                        <p class="">{{'top.email' | translate}}</p>
                        <input [maxLength]="126" formControlName="email" type="email" pInputText class="p-inputtext-sm"/>
                        <div *ngIf="formUser.controls['email'].invalid && (formUser.controls['email'].dirty || submitFlag)" class="alert p-error">
                            <div *ngIf="formUser.controls['email'].errors?.['required']">
                                {{ "top.email" | translate }}{{ mes.MSG_056 }}
                            </div>
                            <div *ngIf="formUser.controls['email'].errors?.['email'] && submitFlag">
                                {{ "top.email" | translate }}{{ mes.MSG_053 }}
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <p class="">{{'top.tel' | translate}}</p>
                        <input [maxLength]="8" formControlName="shipId" type="tel" pInputText class="p-inputtext-sm"/>
                        <div *ngIf="formUser.controls['shipId'].invalid && (formUser.controls['shipId'].dirty || submitFlag)" class="alert p-error">
                            <div *ngIf="formUser.controls['shipId'].errors?.['required']">
                                {{ "top.tel" | translate }}{{ mes.MSG_056 }}
                            </div>
                        </div>
                    </div>
                    <button (click)="moveToConfirm()" pButton class="custom-button p-button-warning btn-pc">{{'top.btnSearch' | translate}}</button>
                </div>
            </form>
        </div>
        <div class="notifications" [innerHTML]="InfoNotifiMultiLang">
        </div>
    </div>
  </div>

  <!-- popup -->
<p-dialog [showHeader]="false" [modal]="true" [contentStyle]="{backgroundColor: '#EBE6C8', padding: '0'}" position="center" [(visible)]="dialogFlag" [style]="{width: '320px'}">
    <div class="close">
        <i class="pi pi-times" (click)="closeDialog()" style="font-size: 15px; padding: 8px;"></i>
    </div>
    <div [formGroup]="formUser">
        <div class="item-info">
            <p>{{'top.email' | translate}}</p>
            <input type="email" [maxLength]="126" formControlName="email" pInputText class="p-inputtext-sm"/>
            <div *ngIf="formUser.controls['email'].invalid && (formUser.controls['email'].dirty || submitFlag)" class="alert p-error">
                <div *ngIf="formUser.controls['email'].errors?.['required']">
                    {{ "top.email" | translate }}{{ mes.MSG_056 }}
                </div>
                <div *ngIf="formUser.controls['email'].errors?.['email'] && submitFlag">
                    {{ "top.email" | translate }}{{ mes.MSG_053 }}
                </div>
            </div>
        </div>
        <div class="item-info">
            <p>{{'top.tel' | translate}}</p>
            <input type="tel" [maxLength]="8" formControlName="shipId" pInputText class="p-inputtext-sm"/>
            <div *ngIf="formUser.controls['shipId'].invalid && (formUser.controls['shipId'].dirty || submitFlag)" class="alert p-error">
                <div *ngIf="formUser.controls['shipId'].errors?.['required']">
                    {{ "top.tel" | translate }}{{ mes.MSG_056 }}
                </div>
            </div>
        </div>
        <div class="bottom">
            <button (click)="moveToConfirm()" pButton class="p-button-warning btn-app">{{'top.btnSearch' | translate}}</button>
        </div>
    </div>
</p-dialog>